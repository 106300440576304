import React from 'react';
import Maps from '../../Images/applemaps.png';
import Konjo from '../../Images/konjologo.png';
import Abe from '../../Images/abelogo.png';
import Kinashe from '../../Images/kinashelogo.png';
import Clutch from '../../Images/clutchlogo.png';
import './Experience.css';


  function Experience() {
    return (
        <div id='Experience'>
                <center className= 'background-pink'>
                <h1 className='fa-times-red'>Experience</h1>
                
                <img className= 'img-experience-pink' width="25%" src={Maps} />

                    <h1 className='fa-medium-red'>
                    Apple Inc.
                    </h1>
                    <br/>

                    <h3 className='job-title'>
                    GIS Operations Lead | Data Analyst 
                    </h3>
                    <br/>

                    <h4 className="job-title">
                    December 2019 – December 2020
                    </h4>
                    <br/>

                    <h4 className="fa-small-red">
                    Managed gis technicians and analysts in various data development and project support initiatives. Revised and digitized datasets for multiple countries for Apple Maps.                     
                    </h4>

                    <img className= 'img-experience-pink' width="25%" src={Konjo} />

                    <h1 className='fa-medium-red'>
                    Konjo
                    </h1>
                    <br/>


                    <h3 className='job-title'>
                    Application Developer (Founder)
                    </h3>
                    <br/>

                    <h4 className="job-title">
                    June 2020 – Present
                    </h4>
                    <br/>

                    <h4 className="fa-small-red">
                    Developed the first dating app (iOS & Android) for Ethiopians and Eritreans. 8000+ users in its first three month.
                    </h4>
                    <br/>

                    <a className = "fa-small-red" href="https://konjoapp.com/">konjoapp.com</a>

                    <br/>

                    <img className= 'img-experience-pink' width="25%" src={Abe} />

                    <h1 className='fa-medium-red'>
                    Abe Towncar Service
                    </h1>
                    <br/>


                    <h3 className='job-title'>
                    Application Developer (Freelance)
                    </h3>
                    <br/>

                    <h4 className="job-title">
                    January 2021 – March 2021
                    </h4>
                    <br/>

                    <h4 className="fa-small-red">
                    Developed booking application for the top towncar service in Seattle, Washington.
                    </h4>
                    <br/>


                    <a className = "fa-small-red" href="https://apps.apple.com/us/app/abe-towncar-service/id1563647380">iOS</a>

                    <a className = "fa-small-red" href="https://play.google.com/store/apps/details?id=com.AbeTownCarService.AbeTownCarService&hl=en_US&gl=US">Android</a>
                    <br/>

                    <img className= 'img-experience-pink' width="25%" src={Clutch} />

                    <h1 className='fa-medium-red'>
                    Clutch Apps
                    </h1>
                    <br/>


                    <h3 className='job-title'>
                    Mobile App Development (Founder)
                    </h3>
                    <br/>

                    <h4 className="job-title">
                    January 2021 – Present
                    </h4>
                    <br/>

                    <h4 className="fa-small-red">
                    Mobile App Freelancing Service
                    </h4>
                    <br/>

                    <a className = "fa-small-red" href="https://clutchapps.co/">clutchapps.co</a>

                    <br/>

                    <img className= 'img-experience-pink' width="25%" src={Kinashe} />

                    <h1 className='fa-medium-red'>
                    Kinashe
                    </h1>
                    <br/>


                    <h3 className='job-title'>
                    Application Developer (Founder)
                    </h3>
                    <br/>

                    <h4 className="job-title">
                    September 2020 – Present
                    </h4>
                    <br/>

                    <h4 className="fa-small-red">
                    iOS, Android, Web developer for the first mobile coupon application for Ethiopians
                    </h4>
                    <br/>

                    <a className = "fa-small-red" href="https://kinashe.com/">kinashe.com</a>
                </center>
        </div>        
    )
}

export default Experience;