import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {FaBars, FaTimes} from 'react-icons/fa';
import Logo from '../Images/erlogo.png';
import './Navbar.css';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";


import {IconContext} from 'react-icons/lib';


function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false)
  

 function AboutMe() {
  const stickyContainer = document.getElementById("About-Me").offsetTop
  window.scrollTo({
    top: stickyContainer - 100,
    behavior: 'smooth'
  });
 };

 function Experience() {
  const stickyContainer = document.getElementById("Experience").offsetTop
  window.scrollTo({
    top: stickyContainer - 100,
    behavior: 'smooth'
  });
 };
 
 function Education() {
  const stickyContainer = document.getElementById("Education").offsetTop
  window.scrollTo({
    top: stickyContainer - 100,
    behavior: 'smooth'
  });
 };

 function Contact() {
  const stickyContainer = document.getElementById("Contact").offsetTop
  window.scrollTo({
    top: stickyContainer - 100,
    behavior: 'smooth'
  });
 };

  return(
    <>
    <IconContext.Provider value={{color: '#fff'}}>
      <div className='navbar' >
          <div className='navbar-container container'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
        <img className= 'navbar-icon' width='50px' src={Logo}/>    
        Emmanuel Robi
        </Link>

          <div className='menu-icon' onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item' onClick={AboutMe}>
              <Link to='/' className='nav-links' onClick={handleClick}>
                About Me 
              </Link>
            </li>
            <li className='nav-item' onClick={Experience}>
              <Link to='/' className='nav-links' onClick={handleClick}>
                Experience
              </Link>
            </li>
            <li className='nav-item' onClick={Education}>
              <Link to='/' className='nav-links' onClick={handleClick}>
                Education
              </Link>
            </li>
            <li className='nav-item' onClick={Contact}>
              <Link to='/' className='nav-links' onClick={handleClick}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
