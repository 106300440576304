import React from 'react'
import './Education.css';
import UW from '../../Images/uwlogo.png';

export default function Education() {
    return (
        <div id="Education" >
        <center className= 'background-blue'>
        <h1 className='fa-times-Education'>Education</h1>
        <br/>

        <img className= 'img-education' width="25%" src={UW} />

        <h1 className='fa-medium-Education'>
        University Of Washington 
        </h1>
        <br/>


        <h4 className="school-description">
        September 2015 – June 2019
        </h4>
        <br/>

        <h3 className='school-description'>
        Major: Geography (GIS Route)
        </h3>
        <h3 className='school-description'>
        Minor: Informatics 
        </h3>
        <br/>

        </center>
        </div>
    )
}