import './App.css';
import Home from "./Components/Pages/Home/HomePage";
import Experience from "./Components/Pages/Experience/Experience";
import Education from "./Components/Pages/Education/Education";
import Contact from "./Components/Pages/Contact/Contact";
import ScrollToTop from './Components/ScrollIntoView/ScrollToTop';
import {BrowserRouter as Router, Switch,Route} from 'react-router-dom';



function App() {

  return (
    <div className= 'background-blue'> 
    <Router>
      <ScrollToTop />
           <Switch>
             <Route path='/' component={Home} exact/>
          </Switch>      
    </Router>
    </div>  

  );
}

export default App;