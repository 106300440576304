import React from 'react';
import { Link } from 'react-router-dom';
import profile from '../../Images/profile.png';
import './Home.css';
import { Container } from "reactstrap";



function Home() {
    return (
        <div id = 'About-Me'>
            <div>
                <center className= 'background-blue'>
                    <h1 className='fa-times'>
                        Emmanuel Robi
                    </h1>
                    <h4 className="fa-small">
                    Entrepreneur · Application Developer · Geographic Information System Developer | Analyst
                    </h4>

                    <img className= 'img-home' width="25%" src={profile} />

                    <h1 className='fa-times'>
                        About Me
                    </h1>

                    <h4 className="fa-medium">
                    Hello, my name is Emmanuel Robi. I graduated from the University of Washington in 2019 in Geography with a minor in Informatics. Over the last two years I’ve started up a few businesses, a dating service, coupon service, and freelancing service. After graduating, my first job was with Apple as a GIS Technician. During my one year at Apple I managed a variety of projects. I’m very passionate about app development. In my free time I develop applications as a hobby and freelancing.                     
                    </h4>
                </center>
            </div>
        </div>
    );
}

export default Home;