import React from 'react';
import './Contact.css';
import firebase from 'firebase';
import { useForm } from "react-hook-form";
import ReactDOM from "react-dom";
import { ToastContainer, toast } from 'react-toastify';


// import emailjs from 'emailjs-com';

export default function Contact() {


  var firebaseConfig = {
    apiKey: "AIzaSyDjTDTaVSlWBB81qKgLXZQDRgzm4Ck-WOE",
    authDomain: "emmanuel-s-portfolio.firebaseapp.com",
    projectId: "emmanuel-s-portfolio",
    storageBucket: "emmanuel-s-portfolio.appspot.com",
    messagingSenderId: "270726940950",
    appId: "1:270726940950:web:39889e4316e48dfc776837",
    measurementId: "G-Z52P55TZXX"
  };
  
  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }else {
    firebase.app(); // if already initialized, use that one
 }
    

  // reference to db 
  let contactInfo = firebase.database().ref("infos");

  const { register, reset, handleSubmit, formState: { errors } } = useForm();



  const onSubmit = (data) => {
        // Getinput values 
        
        let name = data.name
        let email = data.email
        let message = data.message
      
        console.log(name, email, message);
        saveContactInfo(name,email,message)

        toast.dark('✅ Thanks, talk to you soon!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
          });
          reset({})
        };

  // Save infos to Firebase
function saveContactInfo(name, email, message) {
  document.getElementById("contact-form").reset();



  let newContactInfo = contactInfo.push();
  const timestamp = Date.now();

  newContactInfo.set({
    name: name,
    email: email,
    message: message,
    timestamp: timestamp
  });

};



  return (
      <div id = 'Contact' className='background-pink'>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        <ToastContainer />

          <h1 className='fa-times-red-center'> Let's Connect</h1>
  <form id = "contact-form" class="contact-form" onSubmit={handleSubmit(onSubmit)}>
    <div className='background-pink'>

    <label className='fa-medium-red-center' for="">Name</label><br />
    {errors.name && <label className='fa-medium-warning'>{errors.name.message}</label>}
    <input name="name" type="text" placeholder="Name" {...register('name', { required: "Please include your name" })} /><br />


    <label className='fa-medium-red-center'for="">Email</label><br />
    {errors.email && <label className='fa-medium-warning'>{errors.email.message}</label>}
    <input name="email" type="email" placeholder="Email" {...register('email', { required: "Please include your email" })}/><br />

    <label className='fa-medium-red-center' for="">Message</label><br />
    {errors.message && <label className='fa-medium-warning'>{errors.message.message}</label>}
    <textarea name="message" id="" cols="30" rows="10" placeholder="Message" {...register('message', { required: "Please include a message" })} ></textarea>


    <br />
    <button type="submit">Submit</button>
    </div>
  </form>
  <script src="https://www.gstatic.com/firebasejs/8.1.2/firebase-app.js"></script>
  </div>
  );
}
