import React from 'react'
import Experience from "../Experience/Experience";
import Education from "../Education/Education";
import Contact from "../Contact/Contact"
import Home from "./Home";
// import toast, { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "../../ScrollIntoView/ScrollToTop"
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';


export default function HomePage() {

    
    return (
        <div className= 'background-blue'>
        <center className= 'background-blue'>
        <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        <ScrollToTop />
        <Navbar/>
        <Home/>
        <Experience/>
        <Education/>
        <Contact/>
        <Footer/>  
        </center>
        </div>
    )
}



